@import "./normalize.css";

* {
  outline: none !important;
}

html {
  /* Removed as this caused tailwind styles to be massively scaled down. */
  /* font-size: 62.5%; */
  font-family: "Open Sans", Helvetica Neue, Arial, sans-serif;
}

body {
  font-size: 15px;
  line-height: 1.4;
  overscroll-behavior-y: none;
}

html,
body,
#root {
  width: 100%;
}

h1,
.h1 {
  font-size: 24px;
  font-family: "Josefin Sans", sans-serif !important;
}

h2,
.h2 {
  font-size: 20px;
  font-family: "Josefin Sans", sans-serif !important;
}

h3,
.h3 {
  font-size: 16px;
  font-family: "Josefin Sans", sans-serif !important;
}

h4,
.h4 {
  font-size: 15px;
  font-family: "Josefin Sans", sans-serif !important;
}

h5,
.h5 {
  font-size: 13px;
  font-family: "Josefin Sans", sans-serif !important;
}

h6,
.h6 {
  font-size: 12px;
  font-family: "Josefin Sans", sans-serif !important;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

[role="tooltip"] {
  z-index: 1999;
}

form label {
  z-index: 99;
}

a:not([role="button"]),
a:not([role="button"]):hover {
  text-decoration: none;
}

/* needed to fix a bug in react-pdf where the text and annotation layers are rendered separately,
essentially replicating the space of each page three times. */
.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  max-height: 0px;
}

/* Center each pdf page in the results visualizer */
.react-pdf__Page__canvas {
  margin-left: auto;
  margin-right: auto;
}

@import "./print.css";

@import "./tables.css";

@import "./prism.css";

@import "./integrations/splitit-overrides.css";

@import "./integrations/mapbox-overrides.css";
