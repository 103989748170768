/* 
  Style popup to have correct border radius and correct shadows
*/
.mapboxgl-popup-content {
  box-shadow: 0px 9px 15px -3px #0000001a, 0px 0px 43px -2px #0000000f,
    0px 0px 16px 0px #00132142;
  border-radius: 8px;
}

.mapboxgl-map {
  z-index: 0;
  -webkit-border-radius: 0.8rem;
}

.mapboxgl-popup-close-button {
  margin: 5px;
  width: 35px;
  height: 35px;
  font-size: 30px;
}

.mapboxgl-ctrl-geocoder {
  border-radius: 30px;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: none;
  width: 100% !important;
  min-width: 100% !important;
}

@media only screen and (max-width: 480px) {
  /* Ensures the mobile popups are smaller than the map width */
  .mapboxgl-popup-content {
    max-width: 340px;
  }
}
