/* 
  Decreases the size of the flex field inputs
*/
.splitit-flex-field {
  height: 35px !important;
  padding: 0.7rem !important;
}

/*
  Colors the focused border to match our primary color
*/
.splitit-flex-field.focused {
  border-color: #0075cd !important;
}

/*
  Hides the label fly-in upon focus.
*/
.splitit-flex-field .splitit-flex-field-label {
  display: none;
}
